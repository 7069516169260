<template>
  <div class="hour-table-container">
    <div class="control">
      <div class="left">
        <a-button type="link" @click="Visible = true"><a-icon type="radar-chart" />维度</a-button>
        <div class="dimension_list">
          <div class="dimension_item" v-for="(item, index) in latitudeArr" :key="item.dataIndex">
            <span>{{ item.name }}</span>
            <span class="dimension_close" @click="handleCloseDimensionItem(index)"><a-icon type="close" /></span>
          </div>
        </div>
      </div>
      <div>
        <a-popover trigger="click" overlayClassName="media_popover" placement="top" @visibleChange="visibleChange">
          <template slot="content">
            <a-checkbox-group
              style="display: flex; flex-direction: column"
              :value="groupByList"
              @change="handleChangeChecked"
            >
              <a-checkbox
                v-for="item in dimensionArr"
                :key="item.dataIndex"
                :value="item.dataIndex"
                style="padding: 10px"
              >
                {{ item.name }}</a-checkbox
              >
            </a-checkbox-group>
          </template>
          <a-button style="padding: 0 10px;margin-right: 20px" type="link" ghost>
            定制指标
            <a-icon :type="popover ? 'up' : 'down'" />
          </a-button>
        </a-popover>
        <a-button
          type="link"
          @click="exportHourReport"
          :loading="exportLoading"
          v-if="dataList.length > 1"
        ><a-icon type="download" />导出</a-button
        >
      </div>
    </div>
    <a-table
      class="m_table_cust"
      size="middle"
      :rowKey="(record, index) => index"
      :data-source="dataList || []"
      :scroll="{ x: 1200 }"
      :columns="column"
      :pagination="false"
      id="hourTable"
      ref="hourTable"
      :loading="isLoading"
      bordered
    >
      <!-- 日期 -->
      <template slot="date" slot-scope="date">
        <div style="display: flex; flex-direction: column">
          <span>{{ getCaption(date, 0) }}</span>
          <span>{{ getCaption(date, 1) }}</span>
        </div>
      </template>
      <!-- 预估收益 -->
      <m-tips
        class="hd_tips"
        slot="incomeTitle"
        :content="`预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`"
        title="预估收益"
      ></m-tips>
      <template slot="income" slot-scope="income">
        <span>{{ income }}</span>
      </template>
      <!-- 预估eCPM -->
      <m-tips
        slot="estimatedRevenueEcpmTitle"
        class="hd_tips"
        :content="`预估收益/${title}统计的展示）*1000`"
        title="预估eCPM"
        :width="150"
      ></m-tips>
      <!-- 流量请求 -->
      <m-tips
        slot="requestTitle"
        class="hd_tips"
        :content="`应用向${title}发送请求的次数，一次流量请求可能触发多次广告请求`"
        title="流量请求"
        :width="150"
      ></m-tips>
      <!-- 流量填充率 -->
      <m-tips
        slot="requestFilledRateTitle"
        class="hd_tips"
        :content="`应用向${title}发送请求后，响应成功的占比`"
        title="流量填充率"
        :width="150"
      ></m-tips>
      <template slot="requestFilledRate" slot-scope="requestFilledRate">{{ requestFilledRate }}%</template>
      <!-- 广告请求 -->
      <m-tips
        slot="advRequestTitle"
        class="hd_tips"
        :content="`${title}向广告平台发送请求的次数。一次流量请求可能触发多次广告请求`"
        title="广告请求"
        :width="150"
      ></m-tips>
      <!-- 广告填充率 -->
      <m-tips
        slot="advRequestFilledRateTitle"
        class="hd_tips"
        :content="`${title}向广告平台发送请求后，返回成功的占比`"
        title="广告填充率"
        :width="150"
      ></m-tips>
      <template slot="advRequestFilledRate" slot-scope="advRequestFilledRate">{{ advRequestFilledRate }}%</template>
      <!-- 展示 -->
      <m-tips
        slot="impressTitle"
        class="hd_tips"
        :content="`${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`"
        title="展示"
        :width="150"
      ></m-tips>
      <!-- 展示率 -->
      <m-tips
        slot="impressRateTitle"
        class="hd_tips"
        :content="`${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/流量填充)`"
        title="展示率"
        :width="150"
      ></m-tips>
      <template slot="impressRate" slot-scope="impressRate">{{ impressRate }}%</template>
      <!-- 点击 -->
      <m-tips
        slot="clickTitle"
        class="hd_tips"
        :content="`${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`"
        title="点击"
        :width="150"
      ></m-tips>
      <!-- 点击率 -->
      <m-tips
        slot="clickRateTitle"
        class="hd_tips"
        :content="`${title}统计的点击率，点击率=点击数/展示数`"
        title="点击率"
        :width="150"
      ></m-tips>
      <template slot="clickRate" slot-scope="clickRate">{{ clickRate }}%</template>
      <!-- 展请率 -->
      <m-tips
        slot="impressRequestRateTitle"
        class="hd_tips"
        :content="`${title}统计到的展示数占Mediatom收到的流量请求比例`"
        title="展请率"
        :width="150"
      ></m-tips>
      <template slot="impressRequestRate" slot-scope="impressRequestRate">{{ impressRequestRate }}%</template>
      <!-- 竞价数 -->
      <m-tips
        slot="askPriceRequestTitle"
        class="hd_tips"
        :content="`${title}服务端向竞价广告平台发起的竞价次数`"
        title="竞价数"
        :width="150"
      ></m-tips>
      <!-- 竞价响应率 -->
      <m-tips
        slot="askPriceFilledRateTitle"
        class="hd_tips"
        :content="`竞价响应率 = 竞价响应次数 / 竞价次数 * 100%`"
        title="竞价响应率"
        :width="150"
      ></m-tips>
      <template slot="askPriceFilledRate" slot-scope="askPriceFilledRate">{{ askPriceFilledRate }}%</template>
      <!-- 竞价响应ecpm -->
      <m-tips
        slot="askPriceTitle"
        class="hd_tips"
        :content="`竞价广告平台在竞价响应时返回的平均eCPM`"
        title="竞价响应eCPM"
        :width="150"
      ></m-tips>
      <!-- 应用 -->
      <template slot="appName" slot-scope="appName, record, index">
        <div class="fixed_item" v-if="index !== 0">
          <a-popover placement="bottom" trigger="hover">
            <template #content>
              <div class="synth-popover">
                <div class="synth-popover-item">
                  <span>应用：{{ appName }}</span>
                </div>
                <div class="synth-popover-item">
                  <span>应用ID：{{ record.appId }}</span>
                  <a-button size="small" type="link" @click="copyId(record.appId)">复制</a-button>
                </div>
              </div>
            </template>
            <IosSvg v-if="record.os === 0" style="width: 14px; height: 14px" />
            <AndroidSvg v-if="record.os === 1" style="width: 14px; height: 14px" />
            {{ appName }}
          </a-popover>
        </div>
      </template>
      <!-- 广告位 -->
      <template slot="placeName" slot-scope="placeName, record, index">
        <div class="fixed_item" v-if="index !== 0">
          <a-popover placement="bottom" trigger="hover">
            <template #content>
              <div class="synth-popover">
                <div class="synth-popover-item">
                  <span>广告位ID：{{ record.placeId }}</span>
                  <a-button size="small" type="link" @click="copyId(record.placeId)">复制</a-button>
                </div>
              </div>
            </template>
            {{ placeName }}
          </a-popover>
        </div>
      </template>
      <!-- 操作系统 -->
      <template slot="os" slot-scope="os">{{ os === 1 ? '安卓' : os === 0 ? 'IOS' : '' }}</template>
    </a-table>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 1"
      show-size-changer
      :current="query.page"
      :total="total"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChangePage"
      @showSizeChange="onShowSizeChange"
      :pageSize="query.limit"
    />
    <m-empty v-if="dataList.length <= 1" style="height: 500px">
      <template #description>暂无数据！</template>
    </m-empty>
    <CustDimension
      v-if="Visible"
      :visible="Visible"
      :allDimesion="allDimesion"
      @modalCancel="Visible = false"
      @changeCustDimension="changeCustDimension"
    />
  </div>
</template>

<script>
import { getHourReport } from '@/api/reportdatas'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
// import XLSX from 'xlsx'
import excel from '@/utils/excel'
import CustDimension from '../../SynthReport/SynthTable/CustDimension'
import { columns, allDimesion, dateArr, dimensionArr } from './list'
import request from '@/utils/request'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { copy } from '@/utils/string'
export default {
  components: { CustDimension, IosSvg, AndroidSvg },
  props: {
    searchquery: {
      default: () => ({}),
      type: Object
    }
  },
  data () {
    return {
      query: {},
      dataList: [],
      timer: null,
      isLoading: false,
      exportLoading: false,
      column: [],
      columns,
      allDimesion,
      dateArr,
      dimensionArr,
      Visible: false,
      popover: false,
      total: 0,
      groupByList: [],
      latitudeArr: [],
      ustomized: [],
      groupByListArr: []
    }
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    })
  },
  watch: {
    searchquery: {
      handler (val) {
        const groupByList = this.query.groupByList
        this.query = {
          ...val,
          groupByList
        }
      },
      deep: true
    },
    query: {
      handler (val) {
        this.getTableData()
      },
      deep: true
    }
  },
  mounted () {
    this.changeCustDimension([])
  },
  methods: {
    getCaption (obj, state) {
      var index = obj.lastIndexOf(' ')
      if (state === 0) {
        obj = obj.substring(0, index)
      } else {
        obj = obj.substring(index + 1, obj.length)
      }
      return obj
    },
    handleCloseDimensionItem (index) {
      this.latitudeArr.splice(index, 1)
      this.changeCustDimension(this.latitudeArr)
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 点击定制指标
    handleChangeChecked (data) {
      this.groupByList = data
      this.ustomized = this.dimensionArr.filter((item) =>
        Array.from(new Set(data))?.some((items) => item.dataIndex === items)
      )
      this.column = Array.from(new Set([...this.dateArr, ...this.latitudeArr, ...this.columns, ...this.ustomized]))
      // const arr = Array.from(new Set([...this.dateArr, ...this.columns, ...this.ustomized]))
      // localStorage.setItem('hoursreportObj', JSON.stringify({ hourGroupList: data, hoursreport: arr }))
      this.getTableData()
    },
    visibleChange () {
      this.popover = !this.popover
    },
    // 修改page
    handleChangePage (page) {
      this.query.page = page
      // this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      // this.getTableData()
    },
    changeCustDimension (data) {
      // 初始化的时候
      if (data.length === 0) {
        this.allDimesion.map((item) => {
          item.checked = false
        })
        this.query.groupByList = []
        this.latitudeArr = []
        // 判断当前是否自定义指标
        // 获取指标列表数组
        // const obj = JSON.parse(localStorage.getItem('hoursreportObj'))
        // if (obj) {
        //   this.column = obj.hoursreport
        //   this.groupByList = obj.hourGroupList
        // } else {
          this.column = [...this.dateArr, ...this.columns]
        // }
      } else {
        // 自定义指标后处理table column
        this.query.groupByList = data?.map((item) => item.key) || []
        this.latitudeArr = data.map((item) => {
          return {
            key: item.key,
            title: item.name,
            dataIndex: item.dataIndex,
            name: item.name,
            width: item.width,
            align: 'left',
            scopedSlots: { customRender: item.dataIndex },
            ellipsis: !item.isWrap
          }
        })
        this.column = [...this.dateArr, ...this.latitudeArr, ...this.columns, ...this.ustomized]
      }
    },
    // 获取列表
    async getTableData () {
      this.isLoading = true
      const resp = await getHourReport(this.query)
      if (resp.code === 200 && resp.data) {
        const obj = { date: '汇总' }
        const list = resp.data.list || []
        const aggregate = resp.data.aggregate || []
        aggregate.date = '汇总'
        this.total = resp.data && resp.data.total
        this.dataList = [...[{ ...obj, ...aggregate }], ...list]
        this.dataList.map((item) => {
          item.dau = numFormat(item.dau, 3, '')
          item.income = numFormat(item.income, 3, '')
          item.estimatedRevenueEcpm = numFormat(item.estimatedRevenueEcpm, 3, '')
          item.request = numFormat(item.request, 3, '')
          item.advRequest = numFormat(item.advRequest, 3, '')
          item.impress = numFormat(item.impress, 3, '')
          item.click = numFormat(item.click, 3, '')
          item.askPriceRequest = numFormat(item.askPriceRequest, 3, '')
          item.advResponse = numFormat(item.advResponse, 3, '')
          item.requestDau = numFormat(item.requestDau, 3, '')
          item.clickDau = numFormat(item.clickDau, 3, '')
        })
      }
      this.isLoading = false
    },
    // dealArrayBuffer (wb) {
    //   // 保留原有参数
    //   const newWb = JSON.parse(JSON.stringify(wb))
    //   // 转数据结构
    //   const arr = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
    //   // 处理列数据
    //   arr.forEach((item) => {
    //     const keys = Object.keys(item)
    //     keys.forEach((key) => {
    //       if (key.includes('率')) {
    //         item[key] = (+item[key] * 100).toFixed(2) + '%'
    //       }
    //     })
    //   })
    //   // 转数据结构，保存数据
    //   newWb.Sheets[wb.SheetNames[0]] = XLSX.utils.json_to_sheet(arr)
    //   return newWb
    // },
    // 导出表格接口函数
    async downloadReport (query) {
      return request.post('/report/downloadHourReport', query, {
        responseType: 'blob'
      })
    },
    // 导出报表
    async exportHourReport () {
      this.exportLoading = true
      const codesList = []
      const namesList = []
      this.column.map((item) => {
        namesList.push(item.name)
        codesList.push(item.dataIndex)
      })
      const {
        dateList = [],
        appIdList = [],
        placeIdList = [],
        positionIdList = [],
        groupIdList = [],
        channelIdList = [],
        platIdList = [],
        sourceIdList = [],
        groupByList = [],
        osIdList = [],
        sdkVersion = '',
        appVersion = ''
      } = this.query || {}
      const parmars = {
        dateList,
        appIdList,
        placeIdList,
        positionIdList,
        groupIdList,
        channelIdList,
        platIdList,
        sourceIdList,
        groupByList,
        osIdList,
        sdkVersion,
        appVersion,
        codeList: codesList,
        nameList: namesList
      }
      try {
        const res = await this.downloadReport(parmars)
        await excel(res, `小时报表.xls`)
        this.exportLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.hour-table-container {
  border-radius: 5px;
  background: #fff;
  margin-top: 10px;
  padding: 20px;
  .control {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .media_popover {
      padding: 10px;
    }
    .left {
      display: flex;
      height: 36px;
      line-height: 36px;
      .dimension_list {
        display: flex;
        height: 100%;
        .dimension_item {
          padding: 0 15px;
          background: #eee;
          border-radius: 5px;
          font-size: 12px;
          margin-left: 20px;
          .dimension_close {
            margin-left: 5px;
            line-height: 12px;
            cursor: pointer;
          }
        }
      }
    }
    button {
      padding: 0 0 10px 0;
    }
  }
  .pagination {
    padding: 10px 10px;
  }
  .fixed_item {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
::v-deep {
  .ant-checkbox-wrapper {
    margin-left: 0px;
  }
}
</style>
